import React, { useState, useEffect } from "react"
import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function additionalStyles(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            const bodyStyle = document.querySelector("body")
            const liElements = document.querySelectorAll("li p")
            const strongAElements = document.querySelectorAll("a strong")
            const strongPElements = document.querySelectorAll("p strong")
            const framerElements = document.querySelectorAll(
                ".framer-1ihhc2c, .framer-rtenfh, .framer-fw1r6k, .framer-1arxxg2, .framer-1t1esci, .framer-r1tjui, .framer-1nk3yrr, .framer-mluvf1, .framer-trrvxk, .framer-3atd9n, .framer-agztrk, .framer-15y6v5e, .framer-u3x9g6, .framer-8n6gj0, .framer-olv40b, .framer-1nh9ea6, .framer-1i745wh, .framer-15tfaow, .framer-qi9uwk, .framer-1aacuz4, .framer-ylnbez, .framer-18icuok, .framer-hvh2hy, .framer-12khla2, .framer-1qwc7rk, .framer-gpl94a, .framer-qasj3i, .framer-53qmsk, .framer-10ovyzq, .framer-1ipjsxi, .framer-nn3zuz, .framer-npox7t, .framer-8yznl0, .framer-1h2aiqi, .framer-a7i5uy, .framer-xthdui, .framer-1puwtpi, .framer-4jcbtn, .framer-14r2761, .framer-1onc58f, .framer-1x1o83s, .framer-1rim3kv, .framer-s9ah3p, .framer-2ixw6h, .framer-c4rpte, .framer-dppnvn, .framer-10pnfy, .framer-1s37sgf, .framer-ek2bun, .framer-1nqwuxx, .framer-gwrvjt, .framer-nqe7eb, .framer-1wpt3bz, .framer-1j5cqj9, .framer-vif4sp, .framer-1qwhdvt, .framer-13ubtj3"
            )

            framerElements.forEach((element) => {
                element.style.minHeight = "min-content"
            })

            strongPElements.forEach((strong) => {
                strong.style.fontFamily = "Aspecta, sans-serif"
            })

            strongAElements.forEach((strong) => {
                strong.style.fontFamily = "Aspecta, sans-serif"
                strong.style.fontWeight = "400"
            })

            liElements.forEach((p) => {
                p.style.lineHeight = "26px"
                p.style.marginBottom = "10px"
            })
        }, [])

        return <Component {...props} />
    }
}

export function withRotate(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component): ComponentType {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            />
        )
    }
}

export function scrollSection(Component): ComponentType {
    return (props) => {
        return (
            <div style={{ overflowY: "auto" }}>
                <div className="overlay"></div>
                <Component {...props} />
            </div>
        )
    }
}

export function withColorInversion(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{
                    filter: "invert(1)",
                }}
            />
        )
    }
}

export function underlineAndHover(Component): ComponentType {
    const WrapperComponent = (props) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    textDecoration: "underline",
                }}
            />
        )
    }

    return WrapperComponent
}

export function overflowHidden(Component): ComponentType {
    return (props) => {
        return (
            <div style={{ overflow: "hidden" }}>
                <Component {...props} />
            </div>
        )
    }
}

export function wordWrap(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    display: "inline-block",
                    width: "100%",
                }}
            />
        )
    }
}
